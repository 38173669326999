var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.rightbottom.html",modifiers:{"hover":true,"rightbottom":true,"html":true}}],attrs:{"title":_vm.$t('absence.duration-calculation-tooltip', {
      baseDuration: _vm.absenceDurationCalculationDto.baseDurationInDays,
      subtractedHalfDays: _vm.absenceDurationCalculationDto.subtractedHalfDaysAtStartOrEnd,
      subtractedWeekend: _vm.absenceDurationCalculationDto.subtractedWeekendInDays,
      subtractedHolidays: _vm.absenceDurationCalculationDto.subtractedHolidaysInDays,
      calculatedDuration: _vm.absenceDurationCalculationDto.calculatedDurationInDays
    })}},[_c('span',{staticStyle:{"cursor":"help"}},[_vm._v(" "+_vm._s(_vm.absenceDurationCalculationDto.calculatedDurationInDays)+" "+_vm._s(_vm.absenceDurationCalculationDto.calculatedDurationInDays === 1 ? _vm.$t('datetime.day.day') : _vm.$t('datetime.day.day-plural'))+" ")]),(_vm.showInfoIcon)?_c('b-icon',{staticClass:"ml-2",attrs:{"icon":"question-circle","variant":"secondary","scale":"0.9"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }