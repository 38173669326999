<template>
  <b-container>
    <b-modal
      id="year-closing-modal"
      v-model="modalControl.yearClosing.show"
      size="lg"
      centered
      :title="$t('vacation.annualClosing.title')"
      :ok-title="yearClosingModalOkTitle"
      :cancel-title="$t('general.cancel')"
      cancel-variant="outline-secondary"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :body-text-variant="bodyTextVariant"
      :footer-bg-variant="footerBgVariant"
      :footer-text-variant="footerTextVariant"
      @ok="performAnnualClosing"
      :ok-disabled="$v.$invalid"
      @hidden="resetYearClosingModal"
    >
      <b-form>
        <b-form-group
          class="mt-4 mb-2"
          label-for="editCorrection"
          label-cols="1"
          label-cols-lg="7"
          :label="$t('vacation.annualClosing.modal.finalCorrection')"
        >
          <b-form-input
            id="editCorrection"
            type="text"
            v-model="$v.editYearClosing.finalCorrectionInDays.$model"
            :state="validateFormInput('finalCorrectionInDays')"
          />
        </b-form-group>
        <b-form-group
          class="mt-4 mb-2"
          label-for="editName"
          label-cols="4"
          label-cols-lg="2"
          :label="$t('vacation.annualClosing.modal.comment')"
        >
          <b-form-input
            id="editName"
            type="text"
            v-model="$v.editYearClosing.comment.$model"
            :state="validateFormInput('comment')"
          />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-container fluid id="headContainer" class="p-0 mb-3">
      <b-row align-h="between" align-v="end">
        <b-col />
        <b-col cols="2" v-if="adminView">
          <b-select id="userStatusFilter" v-model="filter.userStatus" :options="statusOptions">
            <template #first>
              <b-form-select-option :value="null">{{ $t('general.all-status') }}</b-form-select-option>
            </template>
          </b-select>
        </b-col>
        <b-col cols="2">
          <b-select :options="yearList" v-model="filter.year" @change="loadVacationInformation()" />
        </b-col>
      </b-row>
    </b-container>

    <b-table small bordered head-variant="light" :fields="tableFields" :items="filteredItems" sort-by="user.fullName">
      <template v-slot:cell(vacationEntitlementInDaysPerYear)="row">
        <span
          style="cursor: help"
          v-b-tooltip.hover.rightbottom.html
          :title="getVacationEntitlementCalculationTooltip(row.item)"
        >
          {{ row.value }}
          <b-icon icon="question-circle" variant="secondary" scale="0.9" />
        </span>
      </template>
      <template v-slot:cell(finalCorrectionInDays)="row">
        <span
          style="cursor: help"
          v-b-tooltip.hover.rightbottom
          :title="row.item.comment"
          :class="{ 'text-secondary': row.item.annualClosingStatus === 'OPEN' }"
        >
          {{ row.value }}
          <b-icon icon="question-circle" variant="secondary" scale="0.9" />
        </span>
      </template>
      <template v-slot:cell(finalYearOverflowInDays)="row">
        <span :class="{ 'text-secondary': row.item.annualClosingStatus === 'OPEN' }">
          {{ row.value }}
        </span>
      </template>
      <template v-slot:cell(annualClosingStatus)="row">
        <b-button
          v-if="adminView && row.item.annualClosingStatus === 'OPEN'"
          @click="openYearClosingModal(row.item)"
          size="xs"
          class="mr-1"
          variant="success"
        >
          <b-icon icon="unlock-fill" aria-hidden="true" />
          {{ $t('vacation.annualClosing.performShort') }}
        </b-button>
        <b-button
          v-else-if="adminView && row.item.annualClosingStatus === 'CLOSED'"
          @click="openYearClosingModal(row.item)"
          size="xs"
          class="mr-1"
          variant="warning"
        >
          <b-icon icon="lock-fill" aria-hidden="true" />
          {{ $t('vacation.annualClosing.withdrawShort') }}
        </b-button>
        <b-badge v-else :variant="row.item.annualClosingStatus === 'CLOSED' ? 'success' : 'secondary'">
          <b-icon :icon="row.item.annualClosingStatus === 'CLOSED' ? 'lock-fill' : 'unlock-fill'" />
          {{ $t('vacation.annualClosing.status.' + row.item.annualClosingStatus.toLowerCase()).toUpperCase() }}
        </b-badge>
      </template>
    </b-table>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import { LocalDate, StaticDates } from '@/util/LocalDateTimeFormatter'
import { StaticSelectOpts } from '@/constants/StaticSelectOpts'
import { cloneDeep as _cloneDeep } from 'lodash'
import { validationMixin } from 'vuelidate'
import { maxLength, required, decimal } from 'vuelidate/lib/validators'
import requiredIf from 'vuelidate/lib/validators/requiredIf'

export default {
  name: 'VacationOverview',
  mixins: [ApiMixin, NotificationMixin, validationMixin],
  props: {
    userId: {
      required: false,
      type: Number,
      default: null
    },
    manageAllUsers: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      vacationInfo: [],
      yearList: [],
      modalControl: {
        yearClosing: {
          show: false
        }
      },
      filter: {
        year: '',
        userStatus: 'ACTIVE'
      },
      statusOptions: StaticSelectOpts.userStatusOptions,
      editYearClosing: {},
      fields: [
        {
          key: 'user.fullName',
          label: this.$t('profile.name'),
          class: 'text-left',
          sortable: false
        },
        {
          key: 'vacationEntitlementInDaysPerYear',
          label: this.$t('vacation.hoursPerYear'),
          class: 'text-center',
          formatter: 'formatDays',
          sortable: false
        },
        {
          key: 'overflowFromPreviousYearInDays',
          label: this.$t('vacation.vacationOverflow'),
          class: 'text-center',
          formatter: 'formatDays',
          sortable: false
        },
        {
          key: 'specialLeaveDays',
          label: this.$t('vacation.specialLeave'),
          class: 'text-center',
          formatter: 'formatDays',
          sortable: false
        },
        {
          key: 'takenVacationDays',
          label: this.$t('vacation.vacationTaken'),
          class: 'text-center',
          formatter: 'formatDays',
          sortable: false
        },
        {
          key: 'remainingVacationDays',
          label: this.$t('vacation.vacationRemaining'),
          class: 'text-center',
          formatter: 'formatDays',
          sortable: false
        },
        {
          key: 'annualClosingStatus',
          label: this.$t('vacation.annualClosing.title'),
          class: 'text-center',
          width: '120px',
          sortable: false
        },
        {
          key: 'finalCorrectionInDays',
          label: this.$t('vacation.finalCorrection'),
          class: 'text-center',
          formatter: 'formatDays',
          sortable: false
        },
        {
          key: 'finalYearOverflowInDays',
          label: this.$t('vacation.finalYearOverflow'),
          class: 'text-center',
          formatter: 'formatDays',
          sortable: false
        }
      ],
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark'
    }
  },
  validations: {
    editYearClosing: {
      comment: {
        required: requiredIf(function (editYearClosing) {
          return editYearClosing.finalCorrectionInDays !== 0
        }),
        maxLength: maxLength(255)
      },
      finalCorrectionInDays: {
        required,
        decimal
      }
    }
  },
  mounted() {
    this.yearList = StaticDates.yearListAsArray(this.$store.getters.getConfig.APP_FIRST_YEAR, LocalDate.nextYear())
    this.filter.year = LocalDate.currentYear()
    this.loadVacationInformation()
  },
  computed: {
    adminView() {
      return this.manageAllUsers && this.isAdmin
    },
    tableFields() {
      // remove the first column 'name' if we're showing this table for the current user only
      return this.adminView ? this.fields : this.fields.slice(1)
    },
    filteredItems() {
      if (this.adminView) {
        let items = this.vacationInfo
        if (this.filter.userStatus !== null && this.filter.userStatus !== '') {
          items = this.vacationInfo.filter(v => {
            return v.user.status === this.filter.userStatus
          })
        }
        return items
      } else {
        return this.vacationInfo
      }
    },
    yearClosingModalOkTitle() {
      if (this.editYearClosing !== null && this.editYearClosing.annualClosingStatus === 'CLOSED') {
        return this.$t('vacation.annualClosing.withdraw')
      }
      return this.$t('vacation.annualClosing.perform')
    }
  },
  methods: {
    validateFormInput(prop) {
      const { $dirty, $invalid } = this.$v.editYearClosing[prop]
      return $dirty ? !$invalid : null
    },
    loadVacationInformation() {
      let self = this
      let filteredYear = self.filter.year
      // avoid duplicate requests on mount and year select @change
      if (
        (self.userId !== null || self.adminView) &&
        (self.vacationInfo.length === 0 || self.vacationInfo.year !== filteredYear)
      ) {
        let baseUrl = `/users/${self.userId}/vacation_infos`
        if (self.adminView) {
          baseUrl = '/vacation_infos'
        }
        let url = `${baseUrl}?year=${filteredYear}`
        this.getRequest(
          url,
          new RequestConfig().onSuccess(res => {
            const info = res.data
            // copy res directly to the vacationInfo array if current user is an admin
            // because then we want to manage a table with all users
            if (self.adminView) {
              self.vacationInfo = info
            }
            // otherwise clear the existing data and add only one item
            else {
              self.vacationInfo.pop()
              self.vacationInfo.push(info)
            }
          })
        )
      }
    },
    formatDays(value) {
      let suffix = value === 1 ? this.$t('datetime.day.day') : this.$t('datetime.day.day-plural')
      return `${value} ${suffix}`
    },
    openYearClosingModal(rowItem) {
      this.editYearClosing = _cloneDeep(rowItem)
      this.modalControl.yearClosing.show = true
    },
    resetYearClosingModal() {
      this.modalControl.yearClosing.show = false
      this.editYearClosing = {}
      this.$v.$reset()
    },
    performAnnualClosing() {
      let self = this
      let currentItem = self.editYearClosing
      let action = 'close'
      if (currentItem.annualClosingStatus === 'CLOSED') {
        action = 'reopen'
      }
      let patchPayload = {
        comment: currentItem.comment,
        finalCorrectionInDays: currentItem.finalCorrectionInDays
      }
      this.patchRequest(
        `/users/${currentItem.user.id}/vacation_infos/${action}_year?year=${currentItem.year}`,
        patchPayload,
        new RequestConfig().onSuccess(() => {
          self.vacationInfo.length = 0
          self.loadVacationInformation()
        })
      )
    },
    getVacationEntitlementCalculationTooltip(item) {
      if (item.vacationEntitlementCalculation === null) {
        // return an empty tooltip for VacationInfo objects which are already closed, because their values come from the DB and calculation is not stored
        return ''
      }
      let tooltip =
        this.$t('vacation.entitlement-calculation-tooltip.base-entitlement', {
          baseEntitlementInDays: this.roundTo3Digits(item.vacationEntitlementCalculation.baseEntitlementInDays)
        }) + '<br/>'
      item.vacationEntitlementCalculation.subtractions.forEach(s => {
        let absenceType = this.$t('absence.types.' + s.absence.type.toLowerCase())
        let absenceStart = this.$dayjs(s.absence.start).format('DD.MM.')
        let absenceEnd = this.$dayjs(s.absence.end).format('DD.MM.')
        tooltip +=
          this.$t('vacation.entitlement-calculation-tooltip.subtraction-cause', {
            absenceType: absenceType,
            absenceStart: absenceStart,
            absenceEnd: absenceEnd,
            subtractionVal: this.roundTo3Digits(s.value)
          }) + '<br/>'
      })
      tooltip += this.$t('vacation.entitlement-calculation-tooltip.entitlement-sum', {
        finalEntitlementInRawDays: this.roundTo3Digits(item.vacationEntitlementCalculation.finalEntitlementInRawDays),
        finalEntitlementInRoundedDays: this.roundTo3Digits(
          item.vacationEntitlementCalculation.finalEntitlementInRoundedDays
        )
      })
      return tooltip
    },
    roundTo3Digits(number) {
      return Math.round(number * 1000) / 1000
    }
  }
}
</script>
