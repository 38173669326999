<template>
  <div>
    <b-container fluid id="headContainer" class="p-0 mb-3">
      <b-row align-h="between" align-v="end">
        <b-col>
          <b-button @click="openEditAbsenceModal(newAbsenceObject)" variant="info">{{ $t('absence.title') }}</b-button>
        </b-col>
        <b-col cols="2">
          <b-select v-if="manageAllUsers" :options="userOptions" v-model="filter.user">
            <template #first>
              <b-form-select-option value="ALL">{{ $t('general.all-users') }}</b-form-select-option>
            </template>
          </b-select>
        </b-col>
        <b-col cols="2">
          <b-select :options="statusOptions" v-model="filter.status">
            <template #first>
              <b-form-select-option value="ALL">{{ $t('general.all-status') }}</b-form-select-option>
            </template>
          </b-select>
        </b-col>
        <b-col cols="2">
          <b-select :options="absenceTypeOptions" v-model="filter.type">
            <template #first>
              <b-form-select-option value="ALL">{{ $t('absence.all-types') }}</b-form-select-option>
            </template>
          </b-select>
          <b-select
            v-if="manageAllUsers && (filter.type === 'SICK_LEAVE' || filter.type === 'CHILD_SICK_LEAVE')"
            :options="healthInsuranceStatusOptions"
            v-model="filter.healthInsuranceStatus"
          >
            <template #first>
              <b-form-select-option value="ALL">{{ $t('absence.all-insurance-statuses') }}</b-form-select-option>
            </template>
          </b-select>
        </b-col>
        <b-col cols="2">
          <b-select :options="monthOptions" v-model="filter.month">
            <template #first>
              <b-form-select-option :value="null">{{ $t('vacation.all-months') }}</b-form-select-option>
            </template>
          </b-select>
        </b-col>
        <b-col cols="2">
          <b-select :options="yearOptions" v-model="filterYear">
            <template #first>
              <b-form-select-option :value="null">{{ $t('vacation.all-years') }}</b-form-select-option>
            </template>
          </b-select>
        </b-col>
      </b-row>
    </b-container>

    <b-table
      id="vacation-datatable"
      small
      striped
      fixed
      responsive
      head-variant="light"
      :fields="tableFields"
      :items="filterAbsences()"
      v-model="visibleTableRows"
      sort-by="start"
    >
      <template #table-colgroup="scope">
        <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.width }" />
      </template>
      <!-- generic cell slot template to add tooltips to truncated text cells in datatables -->
      <template v-slot:cell()="row">
        <span v-ellipsis:bottom="row.value">{{ row.value }}</span>
      </template>
      <template v-slot:cell(duration)="row">
        <AbsenceDurationWithCalculationTooltip :absence-duration-calculation-dto="row.value" :show-info-icon="true" />
      </template>
      <template v-slot:cell(healthInsuranceStatus)="row">
        <b-badge
          v-if="row.item.electronicSickNoteAvailable"
          style="vertical-align: middle"
          class="mr-1"
          variant="success"
          >{{ $t('absence.electronic-sick-note.status-badge') }}</b-badge
        >
        <b-badge
          v-if="!!row.value"
          :variant="row.value === 'BILLED' ? 'success' : 'warning'"
          style="vertical-align: middle"
          >{{ $t('absence.insurance-status-' + row.value.toLowerCase()) }}</b-badge
        >
      </template>
      <template v-slot:cell(status)="row">
        <b-button
          id="buttonAccept"
          v-show="manageAllUsers || row.item.status === 'ACCEPTED'"
          @click="updateStatus(row.item, 'ACCEPTED')"
          size="xs"
          class="mr-1"
          :title="$t('vacation.accepted')"
          :disabled="!manageAllUsers"
          pill
          :variant="row.item.status === 'ACCEPTED' ? 'success' : 'outline-success'"
        >
          <b-icon icon="check" aria-hidden="true" scale="1.5" />
        </b-button>
        <b-button
          v-show="manageAllUsers || row.item.status === 'REJECTED'"
          id="buttonReject"
          @click="updateStatus(row.item, 'REJECTED')"
          size="xs"
          class="mr-1"
          :title="$t('vacation.rejected')"
          :disabled="!manageAllUsers"
          pill
          :variant="row.item.status === 'REJECTED' ? 'danger' : 'outline-danger'"
        >
          <b-icon icon="x" aria-hidden="true" scale="1.5" />
        </b-button>
        <b-button
          id="buttonReview"
          v-show="manageAllUsers || row.item.status === 'REVIEW'"
          :disabled="!manageAllUsers"
          @click="updateStatus(row.item, 'REVIEW')"
          size="xs"
          class="mr-1"
          :title="$t('vacation.review')"
          pill
          :variant="row.item.status === 'REVIEW' ? 'info' : 'outline-info'"
        >
          <b-icon icon="eye" aria-hidden="true" scale="1.2" />
        </b-button>
        <b-button
          id="buttonCreated"
          v-show="!manageAllUsers && row.item.status === 'CREATED'"
          :disabled="true"
          size="xs"
          class="mr-1"
          :title="$t('vacation.created')"
          pill
          variant="primary"
        >
          <b-icon icon="stopwatch" aria-hidden="true" scale="1.2" />
        </b-button>
      </template>
      <template v-slot:cell(crudActions)="row">
        <b-button
          @click="openEditAbsenceModal(row.item)"
          size="xs"
          class="mr-1"
          :disabled="crudButtonsDisabled(row.item)"
        >
          <b-icon icon="pencil" aria-hidden="true" />
        </b-button>
        <b-button
          @click="openDeleteAbsenceModal(row.item)"
          size="xs"
          class="mr-1"
          variant="danger"
          :disabled="crudButtonsDisabled(row.item)"
        >
          <b-icon icon="trash" aria-hidden="true" />
        </b-button>
      </template>
      <template slot="bottom-row">
        <td v-if="manageAllUsers" />
        <td />
        <td />
        <td class="font-weight-bold">{{ totalAbsenceDays.toFixed(2) }} {{ $t('datetime.day.day-plural') }}</td>
        <td />
        <td />
        <td v-if="manageAllUsers" />
        <td />
        <td />
      </template>
    </b-table>

    <b-modal
      id="absence-edit-modal"
      v-model="modalControl.edit.show"
      size="lg"
      centered
      :title="$t('absence.title')"
      :ok-title="$t('general.save')"
      :cancel-title="$t('general.cancel')"
      cancel-variant="outline-secondary"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :body-text-variant="bodyTextVariant"
      :footer-bg-variant="footerBgVariant"
      :footer-text-variant="footerTextVariant"
      @ok="updateAbsence"
      :ok-disabled="$v.$invalid"
      @hidden="resetEditModal"
    >
      <b-form class="editAbsence">
        <b-form-group
          v-if="manageAllUsers"
          class="mt-4"
          label-for="editUser"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('general.user')"
        >
          <b-form-select
            id="editUser"
            v-model="$v.editAbsence.user.$model"
            :state="validateFormInput('user')"
            :disabled="!manageAllUsers"
            :options="userOptions"
          />
        </b-form-group>
        <b-form-group
          class="mt-4"
          label-for="editAbsenceType"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('absence.type')"
        >
          <b-form-select
            id="editAbsenceType"
            v-model="$v.editAbsence.type.$model"
            :state="validateFormInput('type')"
            :placeholder="$t('absence.select-absence-type')"
            :options="absenceTypeOptions"
          >
          </b-form-select>
        </b-form-group>
        <b-form-group
          v-if="manageAllUsers"
          class="mt-4"
          label-for="editStatus"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('general.status')"
        >
          <b-form-select
            id="editStatus"
            placeholder="status"
            v-model="$v.editAbsence.status.$model"
            :state="validateFormInput('status')"
            :options="statusOptions"
          >
          </b-form-select>
        </b-form-group>
        <b-form-group
          class="mt-4 mb-1"
          label-for="dateRangeEdit"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('datetime.timespan')"
          :state="validateFormInput('start') && validateFormInput('end')"
        >
          <date-picker
            id="dateRangeEdit"
            range
            v-bind:placeholder="$t('absence.select-vacation-days')"
            v-model="range"
            title-format="DD.MM.YYYY"
            format="DD.MM.YYYY"
            range-separator="  —  "
            required
            @change="calculateAbsenceDuration()"
          />
        </b-form-group>
        <b-form-group
          class="mt-2 mb-1"
          label-for="halfDayOptions"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('vacation.set-half-days')"
        >
          <b-row class="py-2">
            <b-col>
              <b-form-checkbox switch v-model="editAbsence.startHalfDay" @change="calculateAbsenceDuration()">
                {{ $t('vacation.start-halfDay-msg') }}
              </b-form-checkbox>
            </b-col>
            <b-col>
              <b-form-checkbox
                switch
                class="mr-1"
                v-model="editAbsence.endHalfDay"
                @change="calculateAbsenceDuration()"
              >
                {{ $t('vacation.end-halfDay-msg') }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form-group>
        <b-form-group class="mt-1" label-cols="4" label-cols-lg="3" :label="$t('vacation.calculated-absence-days')">
          <div class="py-2">
            <AbsenceDurationWithCalculationTooltip
              v-if="editAbsenceDateRangeIsSet && (absenceDurationCalculation !== null || editAbsence.duration)"
              :absence-duration-calculation-dto="
                editAbsence.duration && absenceDurationCalculation === null
                  ? editAbsence.duration
                  : absenceDurationCalculation
              "
              :show-info-icon="true"
            />
            <span v-else-if="editAbsenceDateRangeIsSet && absenceDurationCalculation === null" class="text-muted">{{
              $t('vacation.info-calculation-running')
            }}</span>
            <span v-else class="text-muted">{{ $t('vacation.info-no-timespan') }}</span>
          </div>
        </b-form-group>
        <b-form-group
          class="mt-4"
          label-for="editComment"
          label-cols="4"
          label-cols-lg="3"
          :label="$t('vacation.comment')"
        >
          <b-form-textarea
            id="editComment"
            :placeholder="$t('vacation.comment-default')"
            v-model="$v.editAbsence.comment.$model"
            :state="validateFormInput('comment')"
            type="text"
            rows="3"
          >
          </b-form-textarea>
        </b-form-group>
        <div v-show="editAbsence.type === 'SICK_LEAVE' || editAbsence.type === 'CHILD_SICK_LEAVE'">
          <b-form-group
            class="mt-4 mb-2"
            label-for="uploadSickNote"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('absence.sick-note')"
          >
            <b-row class="py-2">
              <b-col>
                <b-form-checkbox switch id="absence-eAU-available" v-model="editAbsence.electronicSickNoteAvailable">
                  {{
                    editAbsence.electronicSickNoteAvailable
                      ? $t(`absence.electronic-sick-note.available.${editAbsence.type}`)
                      : $t(`absence.electronic-sick-note.not-available.${editAbsence.type}`)
                  }}
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group
            v-if="manageAllUsers"
            class="mt-4"
            label-for="editHealthInsuranceStatus"
            label-cols="4"
            label-cols-lg="3"
            :label="$t('absence.insurance-status')"
          >
            <b-form-select
              id="editHealthInsuranceStatus"
              placeholder="status"
              v-model="editAbsence.healthInsuranceStatus"
              :options="healthInsuranceStatusOptions"
              :disabled="editAbsence.status === 'CREATED' || editAbsence.status === 'REVIEW'"
            />
          </b-form-group>
        </div>
      </b-form>
    </b-modal>
    <b-modal
      id="vacation-delete-modal"
      v-model="modalControl.delete.show"
      size="lg"
      centered
      :title="$t('vacation.delete')"
      :ok-title="$t('general.delete')"
      ok-variant="danger"
      :cancel-title="$t('general.cancel')"
      cancel-variant="outline-secondary"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :body-text-variant="bodyTextVariant"
      :footer-bg-variant="footerBgVariant"
      :footer-text-variant="footerTextVariant"
      @ok="deleteAbsence"
    >
      {{ $t('absence.delete-text') }}
    </b-modal>
  </div>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import { LocalDate, LocalDateTimeFormatter, StaticDates } from '@/util/LocalDateTimeFormatter'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/en'
import 'vue2-datepicker/locale/de'
import dayjs from 'dayjs'
import { UserUtil } from '@/util/UserUtil'
import { validationMixin } from 'vuelidate'
import { maxLength, required } from 'vuelidate/lib/validators'
import { StaticSelectOpts } from '@/constants/StaticSelectOpts'
import { cloneDeep as _cloneDeep } from 'lodash'
import AbsenceDurationWithCalculationTooltip from '@/components/AbsenceDurationWithCalculationTooltip'

export default {
  name: 'AbsenceTable',
  mixins: [ApiMixin, NotificationMixin, validationMixin],
  components: { DatePicker, AbsenceDurationWithCalculationTooltip },
  props: {
    allAbsences: {
      required: false,
      type: Array,
      default() {
        return []
      }
    },
    userList: {
      required: false,
      type: Array,
      default() {
        return []
      }
    },
    reloadFunction: {
      required: true,
      type: Function
    },
    manageAllUsers: {
      required: true,
      type: Boolean,
      default: false
    }
  },
  created() {
    this.editAbsence = _cloneDeep(this.objectModels.absence)
    this.yearOptions = StaticDates.yearListAsSelectArray(
      this.$store.getters.getConfig.APP_FIRST_YEAR,
      LocalDate.nextYear()
    )
  },
  mounted() {
    DatePicker.locale(this.locale)
    // set a default filter to the current year
    this.filter.year = LocalDate.currentYear()
  },
  watch: {
    locale(newLocale) {
      DatePicker.locale(newLocale)
    }
  },
  data() {
    return {
      objectModels: {
        absence: {
          id: null,
          start: '',
          end: '',
          user: {},
          startHalfDay: false,
          endHalfDay: false,
          comment: '',
          type: null,
          status: '',
          healthInsuranceStatus: null,
          electronicSickNoteAvailable: false
        }
      },
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark',
      defaultTableFields: [
        { key: 'user.fullName', label: this.$t('profile.name'), width: '200px', sortable: true },
        {
          key: 'start',
          label: this.$t('general.start'),
          formatter: 'formatDate',
          class: 'text-center',
          width: '100px',
          sortable: true
        },
        {
          key: 'end',
          label: this.$t('general.end'),
          formatter: 'formatDate',
          class: 'text-center',
          width: '100px',
          sortable: true
        },
        {
          key: 'duration',
          label: this.$t('datetime.duration'),
          class: 'text-center',
          width: '100px'
        },
        {
          key: 'type',
          label: this.$t('general.type'),
          formatter: 'formatAbsenceType',
          class: 'text-center',
          width: '200px',
          sortable: true
        },
        { key: 'comment', label: this.$t('general.comment'), sortable: false },
        {
          key: 'healthInsuranceStatus',
          label: this.$t('absence.insurance-status'),
          class: 'text-center',
          width: '120px',
          sortable: true
        },
        { key: 'status', label: this.$t('general.status'), class: 'text-center', width: '100px', sortable: true },
        {
          key: 'crudActions',
          label: this.$t('general.table.actions'),
          width: '80px',
          class: 'text-center',
          sortable: false
        }
      ],
      visibleTableRows: [],
      editAbsence: {},
      absenceTypeOptions: StaticSelectOpts.absenceTypeOptions,
      absenceDurationCalculation: null,
      modalControl: {
        edit: {
          show: false
        },
        delete: {
          show: false
        }
      },
      statusOptions: StaticSelectOpts.absenceStatusOptions,
      healthInsuranceStatusOptions: StaticSelectOpts.healthInsuranceStatusOptions,
      monthOptions: StaticDates.monthListAsSelectArray(),
      yearOptions: [],
      filter: {
        status: 'ALL',
        healthInsuranceStatus: 'ALL',
        type: 'ALL',
        user: 'ALL',
        month: null,
        year: null
      }
    }
  },
  validations: {
    editAbsence: {
      user: {
        required
      },
      type: {
        required
      },
      start: {
        required
      },
      end: {
        required
      },
      comment: {
        required,
        maxLength: maxLength(255)
      },
      status: {
        required
      }
    }
  },
  computed: {
    filterYear: {
      get() {
        return this.filter.year
      },
      set(value) {
        this.$emit('year-change', value === null ? this.yearOptions.map(y => y.value) : [value])
        this.filter.year = value
      }
    },
    locale() {
      return this.$store.state.locale
    },
    user() {
      return this.$store.getters.getCurrentUser
    },
    tableFields() {
      // remove the first column 'name' if we're showing this table for the current user only
      if (this.manageAllUsers) {
        return this.defaultTableFields
      } else {
        let arr = this.defaultTableFields.slice(1)
        delete arr[5]
        return arr
      }
    },
    userOptions() {
      return UserUtil.getSelectOptsForUserListGroupedByStatus(this.userList)
    },
    range: {
      get: function () {
        return [new Date(this.$v.editAbsence.start.$model), new Date(this.$v.editAbsence.end.$model)]
      },
      set: function (newRange) {
        this.$v.editAbsence.start.$model = LocalDateTimeFormatter.toDate(newRange[0])
        this.$v.editAbsence.end.$model = LocalDateTimeFormatter.toDate(newRange[1])
      }
    },
    totalAbsenceDays() {
      return this.visibleTableRows.reduce((accum, item) => {
        return accum + parseFloat(item.duration.calculatedDurationInDays)
      }, 0.0)
    },
    newAbsenceObject() {
      let newAbsence = _cloneDeep(this.objectModels.absence)
      newAbsence.user = this.user
      newAbsence.status = 'CREATED'
      return newAbsence
    },
    editAbsenceDateRangeIsSet() {
      return (
        this.editAbsence.start !== '' &&
        this.editAbsence.start !== null &&
        this.editAbsence.end !== '' &&
        this.editAbsence.end !== null
      )
    }
  },
  methods: {
    validateFormInput(prop) {
      const { $dirty, $invalid } = this.$v.editAbsence[prop]
      return $dirty ? !$invalid : null
    },
    calculateAbsenceDuration() {
      let self = this
      if (self.editAbsenceDateRangeIsSet) {
        this.postRequest(
          '/absences/calculate_absence_days',
          this.editAbsence,
          new RequestConfig().onSuccess(res => {
            self.absenceDurationCalculation = res.data
          })
        )
      } else {
        self.absenceDurationCalculation = null
      }
    },
    updateAbsence() {
      if (this.editAbsence.id) {
        this.patchAbsence('/absences/' + this.editAbsence.id, this.editAbsence)
      } else {
        this.saveAbsenceRequest('/absences', this.editAbsence)
      }
    },
    saveAbsenceRequest(url, absence) {
      let self = this
      this.postRequest(
        url,
        absence,
        new RequestConfig().onSuccess(() => {
          self.displaySuccess(self.$t('absence.update-success-msg'))
          this.reloadFunction()
        })
      )
    },

    patchAbsence(url, absencePatch) {
      let self = this
      this.patchRequest(
        url,
        absencePatch,
        new RequestConfig().onSuccess(() => {
          self.displaySuccess(self.$t('absence.update-success-msg'))
          this.reloadFunction()
        })
      )
    },
    deleteAbsence() {
      let self = this
      const id = self.editAbsence.id
      this.deleteRequest(
        `/absences/${id}`,
        new RequestConfig().onSuccess(() => {
          self.displaySuccess(this.$t('absence.delete-success-message'))
          this.reloadFunction()
        })
      )
    },
    updateStatus(absence, status) {
      this.editAbsence = _cloneDeep(absence)
      this.editAbsence.status = status
      this.updateAbsence('/absences/' + this.editAbsence.id + '/', this.editAbsence)
      this.reloadFunction()
    },
    filterAbsences() {
      let absences = this.allAbsences || []
      if (this.manageAllUsers && this.filter.user !== 'ALL') {
        absences = absences.filter(vac => {
          return vac.user.userName === this.filter.user.userName
        })
      }
      if (this.filter.status !== 'ALL') {
        absences = absences.filter(vac => {
          return vac.status === this.filter.status
        })
      }
      if (this.filter.healthInsuranceStatus !== 'ALL') {
        absences = absences.filter(vac => {
          return vac.healthInsuranceStatus === this.filter.healthInsuranceStatus
        })
      }
      if (this.filter.type !== 'ALL') {
        absences = absences.filter(vac => {
          return vac.type === this.filter.type
        })
      }
      if (this.filter.year !== null) {
        absences = absences.filter(vac => {
          let startYear = dayjs(vac.start).year()
          let endYear = dayjs(vac.end).year()
          return startYear === this.filter.year || endYear === this.filter.year
        })
      }
      if (this.filter.month !== null) {
        absences = absences.filter(vac => {
          let startMonth = dayjs(vac.start).month() + 1
          let endMonth = dayjs(vac.end).month() + 1
          return startMonth === this.filter.month || endMonth === this.filter.month
        })
      }
      return absences
    },
    openEditAbsenceModal(row) {
      this.editAbsence = _cloneDeep(row)
      this.modalControl.edit.show = true
    },
    openDeleteAbsenceModal(row) {
      this.editAbsence = _cloneDeep(row)
      this.modalControl.delete.show = true
    },
    formatDate(value) {
      return LocalDateTimeFormatter.formatLocaleDate(value)
    },
    formatAbsenceType(type) {
      return this.$t('absence.types.' + type.toLowerCase())
    },
    crudButtonsDisabled(item) {
      return !this.manageAllUsers && item.status !== 'CREATED'
    },
    resetEditModal() {
      this.modalControl.edit.show = false
      this.editAbsence = _cloneDeep(this.objectModels.absence)
      this.$v.$reset()
      this.absenceDurationCalculation = null
    }
  }
}
</script>

<style lang="scss">
#dateRangeEdit {
  width: 100%;
  height: 100%;
}
</style>
