<template>
  <span
    v-b-tooltip.hover.rightbottom.html
    :title="
      $t('absence.duration-calculation-tooltip', {
        baseDuration: absenceDurationCalculationDto.baseDurationInDays,
        subtractedHalfDays: absenceDurationCalculationDto.subtractedHalfDaysAtStartOrEnd,
        subtractedWeekend: absenceDurationCalculationDto.subtractedWeekendInDays,
        subtractedHolidays: absenceDurationCalculationDto.subtractedHolidaysInDays,
        calculatedDuration: absenceDurationCalculationDto.calculatedDurationInDays
      })
    "
  >
    <span style="cursor: help">
      {{ absenceDurationCalculationDto.calculatedDurationInDays }}
      {{
        absenceDurationCalculationDto.calculatedDurationInDays === 1
          ? $t('datetime.day.day')
          : $t('datetime.day.day-plural')
      }}
    </span>
    <b-icon v-if="showInfoIcon" icon="question-circle" variant="secondary" scale="0.9" class="ml-2" />
  </span>
</template>

<script>
export default {
  name: 'AbsenceDurationWithCalculationTooltip',
  props: {
    absenceDurationCalculationDto: {
      required: true,
      type: Object
    },
    showInfoIcon: {
      required: false,
      type: Boolean,
      default: false
    }
  }
}
</script>
